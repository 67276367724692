module.exports = {
  siteTitle: 'Wilhelm Eklund | Freelance consultant',
  siteDescription: 'Coding, coaching and consulting in Stockholm, Sweden.',
  siteKeywords: 'Wilhelm Eklund, full-stack developer, agile coach, consultant, Stockholm',
  siteUrl: 'https://wilhelmeklund.com',
  siteLanguage: 'en_US',

  googleVerification: 'DLpXuk9GAqlhZflf6lJ4f4eYUg8mh968bF13IyIB9BU',

  name: 'Wilhelm Eklund',
  location: 'Stockholm, Sweden',
  email: 'wille@wilhelmeklund.com',
  socialMedia: [
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/wilhelmeklund/',
    },
    {
      name: 'Github',
      url: 'https://github.com/willeeklund/',
    },
  ],

  nav: [
    {
      name: 'About',
      url: '#about',
    },
    {
      name: 'Projects',
      url: '#projects',
    },
    {
      name: 'Experience',
      url: '#jobs',
    },
    {
      name: 'Contact',
      url: '#contact',
    },
  ],

  twitterHandle: '@wilhelmeklund',
  googleAnalyticsID: 'UA-19663486-1',

  headerHeight: 100,

  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => {
    return {
      origin: 'bottom',
      distance: '20px',
      duration: 500,
      delay,
      rotate: { x: 0, y: 0, z: 0 },
      opacity: 0,
      scale: 1,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      mobile: true,
      reset: false,
      useDelay: 'always',
      viewFactor: 0.25,
      viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
    };
  },
};
